import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";

const StrategiaSocialMediaPage = ({ location: { pathname } }) => {
  if (typeof window !== `undefined`) {
    (function ($) {
      $.fn.shuffleLetters = function (prop) {
        var options = $.extend(
          {
            step: 1, // How many times should the letters be changed
            fps: 30, // Frames Per Second
            text: "", // Use this text instead of the contents
            callback: function () {}, // Run once the animation is complete
          },
          prop
        );

        return this.each(function () {
          var el = $(this),
            str = "";

          // Preventing parallel animations using a flag;
          if (el.data("animated")) {
            return true;
          }
          el.data("animated", true);

          if (options.text) {
            str = options.text.split("");
          } else {
            str = el.text().split("");
          }

          // The types array holds the type for each character;
          // Letters holds the positions of non-space characters;

          var types = [],
            letters = [];

          // Looping through all the chars of the string

          for (var i = 0; i < str.length; i++) {
            var ch = str[i];

            if (ch === " ") {
              types[i] = "space";
              continue;
            } else if (/[a-z]/.test(ch)) {
              types[i] = "lowerLetter";
            } else if (/[A-Z]/.test(ch)) {
              types[i] = "upperLetter";
            }

            letters.push(i);
          }

          el.html("");

          // Self executing named function expression:

          (function shuffle(start) {
            // This code is run options.fps times per second
            // and updates the contents of the page element

            var i,
              len = letters.length,
              strCopy = str.slice(0); // Fresh copy of the string

            if (start > len) {
              // The animation is complete. Updating the
              // flag and triggering the callback;

              el.data("animated", false);
              options.callback(el);
              return;
            }

            // All the work gets done here
            for (i = Math.max(start, 0); i < len; i++) {
              // The start argument and options.step limit
              // the characters we will be working on at once

              if (i < start + options.step) {
                // Generate a random character at thsi position
                strCopy[letters[i]] = randomChar(types[letters[i]]);
              } else {
                strCopy[letters[i]] = "";
              }
            }

            el.text(strCopy.join(""));

            setTimeout(function () {
              shuffle(start + 1);
            }, 2550 / options.fps);
          })(-options.step);
        });
      };

      function randomChar(type) {
        var pool = "";

        if (type === "lowerLetter") {
          pool = "abcdefghijklmnopqrstuvwxyz";
        } else if (type === "upperLetter") {
          pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }

        var arr = pool.split("");
        return arr[Math.floor(Math.random() * arr.length)];
      }
    })($);

    $(function () {
      var container = $("#shuffle");
      container.shuffleLetters();

      function shuffle(text) {
        container.shuffleLetters({
          text: text,
        });
      }

      var arr = ["You", "We"];
      var i = 0;
      setInterval(function () {
        shuffle(arr[i++ % arr.length]);
      }, 2000);
    });
  }

  return (
    <Layout
      seo={{
        title: "Strategia komunikacji social media",
        description:
          "Zaczniij od konkretnego planu działania. W dedykowanej strategii social media przygotujemy dla Ciebie: propozycję komunikacji, big idea, cykle tematyczne postów, przykładow posty. Strategia social media pozwoli Ci na wyznaczaczenie spójnego kierunku działań. Stwórz z nami plan działnania w social media dla Facebooka, Instagrama czy Linkedin. ",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Strategia komunikacji <br /> <span>social media</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#social-media">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Social Media:</p>
            <ul>
              <li>
                <Link to="/audyt-kanalow-social-media/">
                  Audyt kanałów social media
                </Link>
              </li>
              <li className="current">
                <a href="#home">
                  Strategia komunikacji <br /> social media
                </a>
              </li>
              <li>
                <Link to="/prowadzenie-strony-na-facebooku/">
                  Prowadzenie strony na Facebooku
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-konta-instagram/">
                  Prowadzenie konta Instagram
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-strony-na-linkedin/">
                  Prowadzenie strony na LinkedIn
                </Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              {/* <li>
                <Link to="/chatbot-w-komunikacji-marki/">
                  Chatbot w komunikacji marki
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-3">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">Zacznijmy od planu</h2>
                <p className="text-col__desc">
                  Facebook, Instagram czy LinkedIn? <br />
                  Kim jest idealny odbiorca Twojej marki? <br />
                  Jak powinieneś do niego mówić? <br />
                  Co zrobić, aby zbudować wokół Twojej marki zaangażowaną
                  społeczność? <br />
                  I najważniejsze… Po co to robisz? <br />
                  Zacznijmy od planu. Opowiedz nam o Twoich celach biznesowych,
                  produkcie i dotychczasowych działaniach i zaplanujmy wspólnie
                  sukces Twojej firmy w social media.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/strategy-sm-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/strategy-sm-img-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Wyróżnij się <br /> na tle konkurencji
                </h2>
                <p className="text-col__desc">
                  Nie sztuką jest prowadzić social media. Sztuką jest wyróżnić
                  się na tle firm, które już tam są i od wielu lat budują wokół
                  siebie zaangażowaną społeczność. Opracuj z nami niebanalną
                  strategię komunikacji, kreatywne cykle postów, wyróżniającą
                  się szatę graficzną i konsekwentnie buduj społeczność
                  zaangażowanych odbiorców, która przełoży się na realizację
                  Twoich celów biznesowych.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-animated-text">
        <div className="row">
          <div className="col-xl-6 offset-xl-4 col-lg-9 offset-lg-3">
            <h2 className="single-offer-section-animated-text__title">
              When <span id="shuffle">You</span> Win
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-icons">
        <div className="row">
          <div className="col-xl-8 col-lg-9 offset-xl-3 offset-lg-3">
            <h2 className="single-offer-section-icons__title">
              Strategia social media to:
            </h2>
            <div className="row row--icons justify-content-center">
              <div className="col-sm-3 item-col">
                <div className="item">
                  <span className="item__icon icon icon--strategy-sm-1"></span>
                  <p className="item__title">
                    Propozycja <br /> komunikacji
                  </p>
                </div>
              </div>
              <div className="col-sm-3 item-col">
                <div className="item">
                  <span className="item__icon icon icon--strategy-sm-2"></span>
                  <p className="item__title">
                    Big <br /> idea
                  </p>
                </div>
              </div>
              <div className="col-sm-3 item-col">
                <div className="item">
                  <span className="item__icon icon icon--strategy-sm-3"></span>
                  <p className="item__title">
                    Cykle tematyczne <br /> postów
                  </p>
                </div>
              </div>
              <div className="col-sm-3 item-col">
                <div className="item">
                  <span className="item__icon icon icon--strategy-sm-4"></span>
                  <p className="item__title">
                    Przykładowe <br /> posty
                  </p>
                </div>
              </div>
              <div className="col-sm-4 item-col">
                <div className="item">
                  <span className="item__icon icon icon--strategy-sm-5"></span>
                  <p className="item__title">
                    Analiza obecnej <br /> kondycji social media
                  </p>
                </div>
              </div>
              <div className="col-sm-4 item-col">
                <div className="item">
                  <span className="item__icon icon icon--strategy-sm-6"></span>
                  <p className="item__title">
                    Kompleksowa analiza <br /> konkurencji
                  </p>
                </div>
              </div>
              <div className="col-sm-4 item-col">
                <div className="item">
                  <span className="item__icon icon icon--strategy-sm-7"></span>
                  <p className="item__title">
                    Analiza grup <br /> docelowych
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-case-study single-offer-section-case-study--strategy-sm">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <h2 className="case-col__title">
              Sprawdź nasze przykładowe <br /> case studies
            </h2>
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/strategy-sm-case-1.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">branża hotelarska</p>
              <p className="post-wrapper__title">
                Społeczność <span>30 000</span> zaangażowanych fanów obiektu
                hotelowego Blue Mountain Resort w ciągu 2 lat.
              </p>
              <p className="post-wrapper__desc">
                Praca nad budową społeczności od zera to nasze ulubione zadanie.
                Sprawdź nasze case study prowadzenia strony na Facebooku dla
                4-gwiazdkowego hotelu wypoczynkowego Blue Mountain Resort od
                pierwszego dnia istnienia obiektu.
              </p>
              <div className="text-wrapper--inner-action">
                <Link
                  to="/case-study/blue-mountain-resort/"
                  className="btn btn-line"
                >
                  więcej
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/strategy-sm-case-2.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">branża nieruchomości</p>
              <p className="post-wrapper__title">
                Magiczna kraina, fascynująca kultura i tylko 44 grosze za
                pozyskanie nowego fana strony na Facebooku Amazing Thailand
                Poland
              </p>
              <p className="post-wrapper__desc">
                Zobacz nasze case study z 2-letniej współpracy nad budową
                społeczności użytkowników zakochanych w Tajlandii na zlecenie
                Tourism Authority of Thailand
              </p>
              <div className="text-wrapper--inner-action">
                <Link to="/amazing-thailand-poland/" className="btn btn-line">
                  więcej
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span className="anchor" id="kontakt"></span>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark
            locales={locales["pl"]}
            select={true}
            socialTypes={true}
          />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default StrategiaSocialMediaPage;
